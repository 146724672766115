export function formatNumber(
  input: number | string,
  locale: string = "en-US"
): string {
  try {
    // Ensure the input is a number
    const number = typeof input === "string" ? parseFloat(input) : input;

    // Check if the number is valid
    if (isNaN(number)) {
      throw new Error("Invalid number input");
    }

    return new Intl.NumberFormat(locale).format(number);
  } catch (error) {
    console.warn("Number formatting failed, returning input as string.", error);
    return input.toString();
  }
}
