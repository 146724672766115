import {
  Box,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";
import { CSSProperties } from "react";
import { BsQuestionCircle } from "react-icons/bs";

interface Props {
  header: string;
  children: React.ReactNode;
  style?: CSSProperties;
  inPortal?: boolean;
}

export default function InfoPopover(props: Props) {
  const Content = () => (
    <PopoverContent>
      <PopoverArrow />
      <PopoverCloseButton />
      {props.header && <PopoverHeader>{props.header}</PopoverHeader>}
      <PopoverBody>{props.children}</PopoverBody>
    </PopoverContent>
  );

  return (
    <Popover>
      <PopoverTrigger>
        <Link mt={"2px"} style={props.style}>
          <BsQuestionCircle />
        </Link>
      </PopoverTrigger>

      {props.inPortal ? (
        <Portal>
          <Content />
        </Portal>
      ) : (
        <Content />
      )}
    </Popover>
  );
}
