import { captureException } from "@sentry/nextjs";
import { useEffect, useRef, useState } from "react";
import useSWR from "swr";
import { ProductResponse } from "../pages/api/plans";
import { SubscriptionInterval } from "../pages/api/plans/stripe-utils";
import { oneTimeProductId } from "../pages/app/account";
import { fetchApi } from "../services/fetch-api";
import { User } from "../types/user";
import { AppError } from "../utils/app-error";
import { charactersToHours } from "../utils/characters-to-hours";
import { getCurrencySymbol } from "../utils/get-currency-symbol";
import { isInFreeTrial } from "../utils/is-in-free-trial";
import { logger } from "../utils/logger";
import { useUser } from "./use-user";

export type UserUsage = {
  id: string;
  estHours: number;
  limit: number;
  name: string;
  currentUsage: number;
  limitReached: boolean;
  price?: number;
  currency?: string;
  interval: SubscriptionInterval;
  inRefundPeriod?: boolean;
  isPartnerPlan?: boolean;
};

export async function fetchPlans<T>(url: string): Promise<T> {
  try {
    return await fetchApi<T>(url);
  } catch (err) {
    throw AppError.fromUnknownError(err);
  }
}

export function usePlans(
  withCheckoutLink = false,
  includeArchived = false,
  promotionCodeId?: string
) {
  const [plansDidLoad, setPlansDidLoad] = useState(false);
  const { base64sessionUser, sessionUser, systemUser, userIsLoading } =
    useUser(false);

  const didPrintWarning = useRef(false);

  let checkoutLinkParams = "";
  let includeArchivedParams = "";
  let invalidCheckoutLink = false;

  if (!userIsLoading) {
    const hasStripeRequirements = Boolean(
      base64sessionUser && sessionUser?.email
    );

    if (withCheckoutLink && hasStripeRequirements) {
      checkoutLinkParams = `customer-id=${base64sessionUser}&email=${sessionUser?.email}`;

      if (promotionCodeId) {
        checkoutLinkParams += `&promotion-code-id=${promotionCodeId}`;
      }
    } else {
      withCheckoutLink &&
        logger.warn("usePlans: withCheckoutLink requires a sessionUser");
      invalidCheckoutLink = true;
    }
  }

  if (includeArchived) {
    includeArchivedParams = "&include-archived=true";
  }

  const shouldLoad =
    !systemUser?.provider ||
    (systemUser?.provider === "stripe" && !userIsLoading);

  const response = useSWR<ProductResponse, AppError>(
    shouldLoad ? `/plans?${checkoutLinkParams}${includeArchivedParams}` : null,
    fetchPlans
  );

  useEffect(() => {
    if (!plansDidLoad && !response.isLoading && response.data) {
      setPlansDidLoad(true);
    }
  }, [response.data, plansDidLoad, response.isLoading]);

  const checkUsageForUser = (user?: User): UserUsage | undefined => {
    if (!user) {
      return undefined;
    }

    if (user.provider && user.provider !== "stripe") {
      const characterLimit =
        user.subscriptionCharacterLimit ||
        user?.primaryOrganization?.maxCharactersPerUser;

      if (!characterLimit) {
        logger.error("Partner user has no subscriptionCharacterLimit");
        return;
      }

      return {
        id: user.provider,
        estHours: charactersToHours(characterLimit),
        limit: characterLimit,
        name: user.provider,
        currentUsage: user.usageCount,
        limitReached: user.usageCount >= characterLimit,
        interval: "month",
        isPartnerPlan: true,
      };
    }

    if (!response?.data) {
      return undefined;
    }

    if (user.primaryOrganization) {
      return {
        id: "organization",
        estHours: charactersToHours(
          user.primaryOrganization.maxCharactersPerUser
        ),
        limit: user.primaryOrganization.maxCharactersPerUser,
        name: user.primaryOrganization.name,
        currentUsage: user.usageCount,
        limitReached:
          user.usageCount >= user.primaryOrganization.maxCharactersPerUser,
        interval: "month",
      };
    }

    // this may not return results in localhost if the user signed up on prod and has prod product
    let plan = response.data?.products.find((p) => p.id === user.productId);
    let isInTrial = isInFreeTrial(user.createdAt);
    const inRefundPeriod = isInTrial;

    if (user.productId === oneTimeProductId) {
      plan = response.data?.products.find((p) => p.price.type === "one_time");
      isInTrial = false;
    }

    if (!plan && didPrintWarning.current === false) {
      didPrintWarning.current = true;
      logger.warn("Could not find plan for user. Fallback to trial.");
    }

    if (!plan || isInTrial) {
      const trialLimit = 1_250_000;

      return {
        id: "trial",
        estHours: charactersToHours(trialLimit),
        limit: trialLimit,
        name: "Trial",
        price: 0,
        currency: plan?.price.currency
          ? getCurrencySymbol(plan.price.currency)
          : "$",
        currentUsage: user.usageCount,
        limitReached: user.usageCount >= trialLimit,
        interval: (plan?.price.recurring?.interval ||
          "once") as SubscriptionInterval,
      };
    }

    let limit = parseInt(plan.characters?.replace(/\./g, ""), 10);

    if (isNaN(limit)) {
      const message = `Limit is not a number: ${plan.id}`;
      logger.error(message);
      captureException(message, { extra: { origin: "usePlans" } });
      limit = 350_000; // setting a default limit and trust users to complain if this happens
    }

    return {
      id: plan.id,
      estHours: charactersToHours(limit),
      limit,
      name: plan.name,
      currentUsage: user.usageCount,
      limitReached: user.usageCount >= limit,
      price: plan.price.unit_amount,
      currency: getCurrencySymbol(plan.price.currency),
      interval: (plan.price?.recurring?.interval ||
        "once") as SubscriptionInterval,
      inRefundPeriod,
    };
  };

  const plansAreLoading = shouldLoad ? response.isLoading : false;

  return {
    plans: response.data,
    productsError: response.error,
    plansAreLoading,
    plansDidLoad,
    userPlan: checkUsageForUser(systemUser),
    invalidCheckoutLink: !response.isLoading && invalidCheckoutLink,
  };
}
