export function getCurrencySymbol(currencyCode: string): string {
  const currencySymbols: { [key: string]: string } = {
    USD: "$", // US Dollar
    EUR: "€", // Euro
    JPY: "¥", // Japanese Yen
    GBP: "£", // British Pound
    AUD: "A$", // Australian Dollar
    CNY: "¥", // Chinese Yuan
  };

  return currencySymbols[currencyCode.toUpperCase()] || currencyCode;
}
