import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import PageContainer from "../../../components/page-container";
import PageContentSection from "../../../components/page-content-section";
import PageHeading from "../../../components/page-heading";
import { useUser } from "../../../hooks/use-user";
import SpinnerFull from "../../../components/spinner-full";
import InfoPopover from "../../../components/info-popover";
import { StdLink } from "../../../components/StdLink";
import { usePlans } from "../../../hooks/use-plans";
import { formatNumber } from "../../../utils/format-number";
import { LocalizationContext } from "../../../hooks/use-localization";
import { useContext, useEffect, useState } from "react";
import { interpolateString } from "../../../utils/interpolate-string";
import { siteConfig } from "../../../configuration/config";
import { fetchApi } from "../../../services/fetch-api";
import { CustomerPortalResponse } from "../../api/user/customer-portal";
import { ResetPasswordLinkResponse } from "../../api/user/reset-password";
import { logger } from "../../../utils/logger";
import { logout } from "../../../utils/logout";

export const oneTimeProductId = "one-time";

export default function Profile() {
  const { l } = useContext(LocalizationContext);
  const user = useUser();
  const { userPlan } = usePlans(false, true);
  const [customerPortalLink, setCustomerPortalLink] = useState<string>();
  const [resetPasswordLink, setResetPasswordLink] = useState<string>();

  user.userIsLoading && <p>{l["app.loading"]}</p>;

  useEffect(() => {
    if (user.systemUser?.provider === "stripe") {
      fetchApi<CustomerPortalResponse>("/user/customer-portal")
        .then(({ customerPortalLink }) => {
          setCustomerPortalLink(customerPortalLink);
        })
        .catch((err: any) => {
          logger.warn(`Unable to fetch customer portal: ${err?.message}`);
        });
    }

    fetchApi<ResetPasswordLinkResponse>("/user/reset-password")
      .then(({ resetPasswordLink }) => {
        setResetPasswordLink(resetPasswordLink);
      })
      .catch((err: any) => {
        logger.warn(`Unable to fetch reset password link: ${err?.message}`);
      });
  }, [user.systemUser]);

  if (user.userIsLoading || !userPlan) {
    return <SpinnerFull />;
  }

  if (!user.systemUser) {
    return (
      <Alert status="error" mb={5}>
        <AlertIcon />
        <AlertTitle>{l["account.unableToLoad"]}</AlertTitle>
        <AlertDescription>{l["app.tryAgain"]}</AlertDescription>
      </Alert>
    );
  }

  const subscriptionManagerLink = `${process.env.NEXT_PUBLIC_STRIPE_CUSTOMER_PORTAL}?prefilled_email=${user.systemUser?.paymentEmail}`;
  const customerPortalLinkWitFallback =
    customerPortalLink || subscriptionManagerLink;

  return (
    <PageContainer>
      <PageHeading subHeading="Subscription management">
        {l["account.title"]}
      </PageHeading>

      {user.systemUser?.paymentFailed && (
        <Alert status="error" mb={5}>
          <AlertIcon />
          <AlertTitle>{l["account.renewFailed"]}</AlertTitle>
          <AlertDescription>
            {l["account.reviewPaymentDetails"]}
          </AlertDescription>
        </Alert>
      )}

      <Flex
        justifyContent={"space-between"}
        direction={{ base: "column", md: "row" }}
        flexWrap={"wrap"}
      >
        {user.systemUser?.paymentFailed ? (
          <PageContentSection>
            <Heading as="h3" size="xs" mb={3}>
              {l["account.subscriptionInactive"]}
            </Heading>
            <Text mb={3}>{l["account.creditCardDeclined"]}</Text>
            <Text mb={3}>
              {l["account.updateCreditCard"]}{" "}
              <Link
                textDecoration={"underline"}
                color={"brand.secondary.main"}
                target="_blank"
                href={customerPortalLinkWitFallback}
              >
                {l["account.subscriptionManagementPage"]}
              </Link>
            </Text>
            <Text>
              {l["account.questionContactUs"]}{" "}
              <StdLink
                href="mailto:support@talkio.ai"
                text="support@talkio.ai"
              />
            </Text>
          </PageContentSection>
        ) : !siteConfig.hideAccountUsage ? (
          <PageContentSection>
            <Heading as="h3" size="xs" mb={3}>
              {l["account.billing"]}
            </Heading>

            <TableContainer data-tid="billing-table">
              <Table variant="simple">
                <Tbody>
                  <Tr>
                    <Td>{l["account.plan"]}</Td>
                    <Td data-pid={userPlan.id}>{userPlan.name}</Td>
                  </Tr>
                  {user.systemUser && !!userPlan.price && (
                    <Tr>
                      <Td>{l["account.amount"]}</Td>
                      <Td>
                        {userPlan.price / 100} {userPlan.currency}
                        {userPlan.interval === "once"
                          ? ""
                          : "/" + userPlan.interval.substring(0, 1)}
                      </Td>
                    </Tr>
                  )}
                  {(!siteConfig.isDefault ||
                    userPlan.id === "organization") && (
                    <Tr>
                      <Td>{l["account.usage"]}</Td>
                      <Td>
                        <Flex>
                          <Text mr={1}>
                            {formatNumber(userPlan.currentUsage)}
                            {" / "}
                            {formatNumber(userPlan.limit)}
                          </Text>
                          <InfoPopover header="What is this?" inPortal>
                            <Text fontSize="sm">
                              {interpolateString(
                                l["account.usageDescription"],
                                {
                                  limit: formatNumber(userPlan.limit),
                                  estHours: userPlan.estHours,
                                }
                              )}
                            </Text>
                          </InfoPopover>
                        </Flex>
                      </Td>
                    </Tr>
                  )}
                  {userPlan.inRefundPeriod && (
                    <Tr>
                      <Td>{l["account.requestRefund"]}</Td>
                      <Td>
                        <Flex>
                          <Text mr={1}>
                            <StdLink
                              href="mailto:refund@talkio.ai"
                              text="refund@talkio.ai"
                            />
                          </Text>
                        </Flex>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </PageContentSection>
        ) : (
          <></>
        )}

        {user.systemUser.productId !== oneTimeProductId && (
          <PageContentSection>
            <Heading as="h3" size="xs" mb={3}>
              {l["account.manageSubscription"]}
            </Heading>
            {user.systemUser.primaryOrganization ? (
              <>
                <Text mb={2} data-tid="subscription-management">
                  {l["account.subscriptionManagedBy"]}{" "}
                  {user.systemUser.primaryOrganization.name}.
                </Text>
                <Text>{l["account.contactAdmin"]}</Text>
              </>
            ) : userPlan.isPartnerPlan ? (
              <>
                <Text mb={2} data-tid="subscription-management-partner">
                  {l["account.subscriptionManagedByPartner"]}{" "}
                  {siteConfig.adminEmail || userPlan.id}
                </Text>
                <Text>
                  {interpolateString(l["account.contactPartner"], {
                    userPlanId: siteConfig.adminEmail || userPlan.id,
                  })}
                </Text>
              </>
            ) : (
              <>
                <Text>{l["account.accessSubscriptionManagement"]}</Text>
                <Button
                  colorScheme={"brand.primary"}
                  color={"gray.800"}
                  as="a"
                  href={customerPortalLinkWitFallback}
                  size="sm"
                  mt={5}
                  target="_blank"
                >
                  {l["account.goToSubscriptionManagement"]}
                </Button>
              </>
            )}{" "}
          </PageContentSection>
        )}

        {(siteConfig.isDefault || siteConfig.reviewLink) && (
          <PageContentSection>
            <Heading as="h3" size="xs" mb={3}>
              {l["account.feedback"]}
            </Heading>
            {siteConfig.isDefault && (
              <Text>
                {l["account.feedbackDescription"]}{" "}
                <Link
                  textDecor={"underline"}
                  href="https://forms.gle/pdTdNMVHxsaonT159"
                  target="_blank"
                >
                  {l["account.feedbackCallToAction"]}
                </Link>
                .
              </Text>
            )}

            {siteConfig.reviewLink && (
              <Text my={5}>
                {l["account.reviewDescription"]}{" "}
                <Link
                  textDecor={"underline"}
                  href={siteConfig.reviewLink}
                  target="_blank"
                >
                  {l["account.reviewCallToAction"]}
                </Link>
              </Text>
            )}
          </PageContentSection>
        )}

        <PageContentSection>
          <Heading as="h3" size="xs" mb={3}>
            {l["app.signOut"]}
          </Heading>
          <Text>{l["account.signOutDescription"]}</Text>

          <Button
            alignSelf="flex-start"
            colorScheme={"brand.primary"}
            bgColor="brand.primary.main"
            color={"brand.primary.mainContrast"}
            size="sm"
            mt={5}
            data-tid="sign-out"
            onClick={() => {
              logout({ callbackUrl: "/" });
            }}
          >
            {l["app.signOut"]}
          </Button>

          {!siteConfig.useTokenAuth &&
            !user.systemUser?.id.includes("google-oauth") &&
            resetPasswordLink && (
              <Button
                alignSelf="flex-start"
                ml={4}
                size="sm"
                mt={5}
                target="_blank"
                as="a"
                href={resetPasswordLink}
              >
                {l["account.changePassword"]}
              </Button>
            )}
        </PageContentSection>
      </Flex>

      <Flex
        justifyContent={"space-between"}
        fontWeight={"bold"}
        opacity={0.2}
        fontSize="xs"
        flexDir={{ base: "column", md: "row" }}
      >
        <Flex mb={2} flexDir={{ base: "column", md: "row" }}>
          <Text>
            ©{new Date().getFullYear()} {siteConfig.copyright}
          </Text>

          <Box display={{ base: "none", md: "block" }} mx={{ base: 1, sm: 2 }}>
            -
          </Box>

          <Link href={`mailto:${siteConfig.adminEmail}`}>
            {siteConfig.adminEmail}
          </Link>

          {siteConfig.adminPhoneNumber && (
            <>
              <Box
                display={{ base: "none", md: "block" }}
                mx={{ base: 1, sm: 2 }}
              >
                -
              </Box>
              <Link href={"tel:" + siteConfig.adminPhoneNumber}>
                {siteConfig.adminPhoneNumber}
              </Link>
            </>
          )}
        </Flex>

        <Flex mb={2} flexDir={{ base: "column", md: "row" }}>
          {user.systemUser.paymentEmail && (
            <>
              <Text>
                {l["account.userEmail"]}: {user.systemUser.paymentEmail}
              </Text>
              <Box
                display={{ base: "none", md: "block" }}
                mx={{ base: 1, sm: 2 }}
              >
                -
              </Box>
            </>
          )}

          <Text>
            {l["account.connection"]}: {process.env.NEXT_PUBLIC_SERVER}
          </Text>
        </Flex>
      </Flex>
    </PageContainer>
  );
}
