import { Box } from "@chakra-ui/react";

interface PageContainer {
  children: React.ReactNode;
}

export default function PageContentSection({ children }: PageContainer) {
  return (
    <Box
      width={{ base: "100%", md: "49%" }}
      p={5}
      backgroundColor={"white"}
      borderRadius={7}
      mb={5}
    >
      {children}
    </Box>
  );
}
